$infoColor: #e4f9ff;
$textColor: #3b6063;
$darkNavy:#1b3940;
$paddingTop: 40px;
$paddingSides: 40px;
$columnWidth: 100;
#skillsSection{
    margin-bottom: 50px;
    width: 100%;
    max-width: 2060px;
}
#skillsSectionTitle{
    display: flex;
    margin-bottom: 10px;
    margin-top: 100px;
    font-size: max(6vw, 35px);
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: $textColor;
    font-family: avenir;
}

#skills{
    margin: 5% 7vw 5% 7vw;
    padding: $paddingTop $paddingSides $paddingTop $paddingSides;
    background-color: $infoColor;
    border-radius: 10px;
    color: $textColor;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 10px;
    column-gap: 10px;
    grid-template-columns: $columnWidth $columnWidth $columnWidth $columnWidth;
}

.flexRow{
    display: flex;
    justify-content: space-between;
}
