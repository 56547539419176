$skyColor: #e4f9ff;
$seaStart: #a7d9f5;
$seaFinish: #032455;
$minSectionWidth: 200px;
body{
}
#page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    overflow-x: scroll;
}

#page::-webkit-scrollbar{
    display: none;
}

#nav{
    width: 100%;
    display: flex;
    align-items: center;
}

#air{
    background-color: $skyColor;
    max-height: 1100px;
    height: min(150vw, 90vh);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    min-width: $minSectionWidth;
    width: 100%;
}

#sea{
    border: solid;
    border-width: 0px;
    border-top-width: 5px;
    border-color: white;
    background-image: linear-gradient($seaStart, $seaFinish);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: $minSectionWidth;
    width: 100%;
    
}
#contact { 
    background-image: linear-gradient($seaFinish, black);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: $minSectionWidth;
    width: 100%;
}