$infoColor: #e4f9ff;
$textColor: #3b6063;
$paddingTop: 30px;
$paddingSides: 80px;

#contactSection{
    margin: 70px;
    color: $infoColor;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.contactLink{
    font-size: max(2vw, 15px);
    display: flex;
    flex-direction: row;
    height: 30px;;
    align-items: center;
    color: $textColor;
   
}

.separator{
    padding-left: 1vw;
    padding-right: 1vw;
}

.contactLink a{
    color: $textColor;
}
a:visited{
    text-decoration: none;
    color: $textColor;
}

