#bar{
    background-color: #e4f9ff;
    position: fixed;
    top: 0;
    width: 100%;
    max-height: 80px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    color: 3b6063;
}

#links{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.navBarItem{
    font-family: Arial, Helvetica, sans-serif;
    color: #3b6063;
    display: block;
    font-size: max(1.5vw, 0.9em);;
    justify-content: center;
}

.navBarItem:hover{
    background-color: #3b6063;
    color: white;
}

.navBarItem.link{
    display: block;
    padding: 15px;
    text-decoration: none;
}

#logo{
    display: flex;
    align-items: center;
}
#logoImg{
    border-radius: 200px;
    height: 50px;
    padding: 10px;
    margin-left: 5px;
}

