$height: max(15vh, 10vw);
$expandedHeight: 400px;
$imageBoxWidth: 40%;
$expandedImageBoxWidth:50%;
$textBoxWidth: 60%;
$expandedTextBoxWidth: 50%;
$sides: 80px;
$top: 50px;
$darkNavy:#1b3940;
$textColor: #3b6063;
$grayBorder: #cbd0d1;
$slightOffWhite: #f0f2f2;
$textBackground: #e8ebeb;
$animationDuration: 0.2s;
$textItemMargin: 30px;

.project{
    display: flex;
    height: $height;
    width: 80%;
    background-color: $slightOffWhite;
    margin-top: $top;
    animation-duration: $animationDuration;
    animation-delay: 0s;
    animation-fill-mode: forwards;
}

.project.reverse{
    flex-direction: row-reverse;
}

.project:hover{
    background-color: white;
}


.projectImageBox{
    display: flex;
    height: $height;
    width: $imageBoxWidth;
    background-color: $darkNavy;
    justify-content: space-around;
    animation-duration: $animationDuration;
    animation-fill-mode: forwards;
}

.projectImage{
    height: auto;
    width: 100%;
    object-fit: contain;
}

.projectText{
    width: $textBoxWidth;
    height: $height;
    padding-left: 30px;
    padding-right: 30px;
    overflow: hidden;
    animation-fill-mode: forwards;
}

.projectText::-webkit-scrollbar{
    display: none;
}

.projectTitle{
    font-size: max(2vw, 20px);
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 20px;
    color: $darkNavy;
}

.projectDescription{
    font-size: max(1vw, 15px);
    color: $textColor;
    margin-bottom: $textItemMargin;
}

.linksSection{
    display: flex;
    flex-direction: row;
    margin-bottom: $textItemMargin;
}

.projectLink{
    font-size: max(1vw, 15px);
    display: flex;
    flex-direction: row;
    height: 30px;
    width: 30vw;
    margin-right: max(1vw, 10px);
    justify-content: center;
    align-items: center;
}

@keyframes expandProject {
    0% {height: $height;}
    100% {height: $expandedHeight;}
}

@keyframes closeProject {
    0% {height: $expandedHeight;}
    100% {height: $height;}
}

@keyframes expandProjectImage {
    0% {height: $height; width: $imageBoxWidth}
    100% {height: $expandedHeight; width: $expandedImageBoxWidth;}
}

@keyframes closeProjectImage {
    0% {height: $expandedHeight; width: $expandedImageBoxWidth;}
    100% {height: $height; width: $imageBoxWidth;}
}

@keyframes expandProjectText {
    0% {height: $height; width: $textBoxWidth; overflow: hidden;}
    100% {height: $expandedHeight; width: $expandedTextBoxWidth; overflow: scroll;}
}

@keyframes closeProjectText {
    0% {height: $expandedHeight; width: $expandedTextBoxWidth; overflow: scroll;}
    100% {height: $height; width: $textBoxWidth; overflow: hidden;}
}