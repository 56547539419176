#headCard {
  margin-top: min(100px, 9vh);
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
}

#text {
  padding-left: 10px;
  margin-right: 50px;
  width: 50%;
  min-height: 300px;
  max-height: 700px;
  display: flex;
  flex-direction: column;
  color: #3b6063;
}

#name {
  padding-bottom: 2vh;
  font-size: max(8vw, 2.7em);
  font-family: avenir;
}

#title {
  font-size: max(4vw, 1.5em);
  padding-bottom: 30px;
}

#description {
  font-size: max(2vw, 12px);
}

#image {
  padding-top: 10px;
}

#face {
  min-width: 100px;
  max-width: 700px;
  width: 30vw;
  border-radius: 500px;
  border: solid #000;
  border-width: 10px;
  border-color: white;
}

