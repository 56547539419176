$darkNavy:#1b3940;
$textColor: #3b6063;
$toggleColor: #1b3940;
$lightBlue: #dbfaff;
$selectedBlue: #a3d1d9;
$sides: 50px;
#projectsSection{
    display: flex;
    flex-direction: column;
    min-height: 1100px;
    width: 100%;
    max-width: 2060px;
    margin-top: max(4vw, 40px);
}

.right{
    margin-left: 500px;
}

#sectionToggle{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 1vw;
    margin-left: $sides;
    margin-right: $sides;
}

.toggleOption{
    display: flex;
    height: 70px;
    min-width: 20%;
    max-width: 30%;
    padding: 20px;
    border: solid;
    border-color: $textColor;
    align-items: center;
    justify-content: center;
    margin: 10px;
    font-size: 2.5vw;
    font-weight: 600;
    color: $textColor;
    background-color: $lightBlue;
    border-radius: 15px;
}

#projectsTitle{
    font-size: max(6vw, 35px);
    font-weight: 500;
    align-self: center;
    margin-bottom: 2vw;
    
    color: $darkNavy;
    font-family: avenir;
}

.toggleOption:hover{
    border-color: $darkNavy;
    color: $darkNavy;
    background-color: $selectedBlue;
}

.selectedToggleOption{
    border-color: $darkNavy;
    color: $darkNavy;
    background-color: $selectedBlue;
}

@keyframes projectSwipeOut {
    0%   {transform: translate(0vw);}
  100% {transform: translate(100vw);}
}

@keyframes projectSwipeIn {
    0%   {transform: translate(-100vw);}
  100% {transform: translate(0vw);}
}

#projects{
    animation-duration: 0.7s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.projectToggled{
    animation-name: projectSwipeIn;
}