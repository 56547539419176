#skillsSection {
  margin-bottom: 50px;
  width: 100%;
  max-width: 2060px;
}

#skillsSectionTitle {
  display: flex;
  margin-bottom: 10px;
  margin-top: 100px;
  font-size: max(6vw, 35px);
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: #3b6063;
  font-family: avenir;
}

#skills {
  margin: 5% 7vw 5% 7vw;
  padding: 40px 40px 40px 40px;
  background-color: #e4f9ff;
  border-radius: 10px;
  color: #3b6063;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 10px;
  column-gap: 10px;
  grid-template-columns: 100 100 100 100;
}

.flexRow {
  display: flex;
  justify-content: space-between;
}

