.project {
  display: flex;
  height: max(15vh, 10vw);
  width: 80%;
  background-color: #f0f2f2;
  margin-top: 50px;
  animation-duration: 0.2s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

.project.reverse {
  flex-direction: row-reverse;
}

.project:hover {
  background-color: white;
}

.projectImageBox {
  display: flex;
  height: max(15vh, 10vw);
  width: 40%;
  background-color: #1b3940;
  justify-content: space-around;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

.projectImage {
  height: auto;
  width: 100%;
  object-fit: contain;
}

.projectText {
  width: 60%;
  height: max(15vh, 10vw);
  padding-left: 30px;
  padding-right: 30px;
  overflow: hidden;
  animation-fill-mode: forwards;
}

.projectText::-webkit-scrollbar {
  display: none;
}

.projectTitle {
  font-size: max(2vw, 20px);
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 20px;
  color: #1b3940;
}

.projectDescription {
  font-size: max(1vw, 15px);
  color: #3b6063;
  margin-bottom: 30px;
}

.linksSection {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.projectLink {
  font-size: max(1vw, 15px);
  display: flex;
  flex-direction: row;
  height: 30px;
  width: 30vw;
  margin-right: max(1vw, 10px);
  justify-content: center;
  align-items: center;
}

@keyframes expandProject {
  0% {
    height: max(15vh, 10vw);
  }
  100% {
    height: 400px;
  }
}
@keyframes closeProject {
  0% {
    height: 400px;
  }
  100% {
    height: max(15vh, 10vw);
  }
}
@keyframes expandProjectImage {
  0% {
    height: max(15vh, 10vw);
    width: 40%;
  }
  100% {
    height: 400px;
    width: 50%;
  }
}
@keyframes closeProjectImage {
  0% {
    height: 400px;
    width: 50%;
  }
  100% {
    height: max(15vh, 10vw);
    width: 40%;
  }
}
@keyframes expandProjectText {
  0% {
    height: max(15vh, 10vw);
    width: 60%;
    overflow: hidden;
  }
  100% {
    height: 400px;
    width: 50%;
    overflow: scroll;
  }
}
@keyframes closeProjectText {
  0% {
    height: 400px;
    width: 50%;
    overflow: scroll;
  }
  100% {
    height: max(15vh, 10vw);
    width: 60%;
    overflow: hidden;
  }
}

