.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 25%;
  scrollbar-width: 0px;
}

.skill.skillIcon {
  justify-content: center;
}

.skillImage {
  max-width: 150px;
  max-height: 150px;
  width: 15vw;
  align-self: center;
}

.skillName {
  font-size: max(1.5vw, 1.5em);
  height: 40px;
  text-align: center;
  font-weight: 700;
}

.skillnamePlaceHolder {
  height: 40px;
}

.skillDesc {
  font-size: max(1vw, 1em);
  animation-duration: 0.3s;
  overflow: hidden;
  text-align: center;
  max-width: 400px;
  max-height: 0px;
  animation-fill-mode: forwards;
}

.skillDesc::-webkit-scrollbar {
  display: none;
}

@keyframes expandSkill {
  0% {
    max-height: 0px;
  }
  100% {
    max-height: 300px;
    overflow: scroll;
  }
}
@keyframes closeSkill {
  0% {
    max-height: 300px;
  }
  100% {
    max-height: 0px;
    overflow: hidden;
  }
}

