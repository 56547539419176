#projectsSection {
  display: flex;
  flex-direction: column;
  min-height: 1100px;
  width: 100%;
  max-width: 2060px;
  margin-top: max(4vw, 40px);
}

.right {
  margin-left: 500px;
}

#sectionToggle {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 1vw;
  margin-left: 50px;
  margin-right: 50px;
}

.toggleOption {
  display: flex;
  height: 70px;
  min-width: 20%;
  max-width: 30%;
  padding: 20px;
  border: solid;
  border-color: #3b6063;
  align-items: center;
  justify-content: center;
  margin: 10px;
  font-size: 2.5vw;
  font-weight: 600;
  color: #3b6063;
  background-color: #dbfaff;
  border-radius: 15px;
}

#projectsTitle {
  font-size: max(6vw, 35px);
  font-weight: 500;
  align-self: center;
  margin-bottom: 2vw;
  color: #1b3940;
  font-family: avenir;
}

.toggleOption:hover {
  border-color: #1b3940;
  color: #1b3940;
  background-color: #a3d1d9;
}

.selectedToggleOption {
  border-color: #1b3940;
  color: #1b3940;
  background-color: #a3d1d9;
}

@keyframes projectSwipeOut {
  0% {
    transform: translate(0vw);
  }
  100% {
    transform: translate(100vw);
  }
}
@keyframes projectSwipeIn {
  0% {
    transform: translate(-100vw);
  }
  100% {
    transform: translate(0vw);
  }
}
#projects {
  animation-duration: 0.7s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.projectToggled {
  animation-name: projectSwipeIn;
}

