#contactSection {
  margin: 70px;
  color: #e4f9ff;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.contactLink {
  font-size: max(2vw, 15px);
  display: flex;
  flex-direction: row;
  height: 30px;
  align-items: center;
  color: #3b6063;
}

.separator {
  padding-left: 1vw;
  padding-right: 1vw;
}

.contactLink a {
  color: #3b6063;
}

a:visited {
  text-decoration: none;
  color: #3b6063;
}

